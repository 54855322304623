@tailwind base;
@tailwind components;
@tailwind utilities;

/* 
  By default, Tailwind resets some styling. 
  We add a "flex column" layout on #root (or .App wrapper),
  so the footer can be pinned at the bottom.
*/

html,
body,
#root {
  height: 100%;
}

.App {
  @apply min-h-screen flex flex-col;
}

/* Keyframes for fade-up & scale-up transitions */
@keyframes fade-up {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes scale-up {
  0% {
    opacity: 0;
    transform: scale(0.98);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.fade-up {
  animation: fade-up 0.8s ease forwards;
}

.scale-up {
  animation: scale-up 0.8s ease forwards;
}
